export default {
  // Auth
  SAVE_USER: 'SAVE_USER',
  SAVE_TOKEN: 'SAVE_TOKEN',
  SAVE_ORGANIZATION: 'SAVE_ORGANIZATION',
  SAVE_HOSTURL: 'SAVE_HOSTURL',
  SAVE_ROLE: 'SAVE_ROLE',
  SAVE_ACCESS_TOKEN: 'SAVE_ACCESS_TOKEN',
  SET_MSAL_INSTANCE: 'SET_MSAL_INSTANCE',
  SET_AZURE_LOGIN: 'SET_AZURE_LOGIN',
  SET_OTP_FLAG: 'SET_OTP_FLAG',
  SET_REQUIRE_OTP: 'SET_REQUIRE_OTP',
  SET_RECEIVE_OTP_ON: 'SET_RECEIVE_OTP_ON',
  SET_IS_GOOGLE_AUTH_ACTIVATED: 'SET_IS_GOOGLE_AUTH_ACTIVATED',
  SET_EMAIL: 'SET_EMAIL',
  SAVE_REFRESH_TOKEN: 'SAVE_REFRESH_TOKEN',
  SET_IS_TOKEN_FLAG: 'SET_IS_TOKEN_FLAG',
  SET_TOKEN: 'SET_TOKEN',
  SET_DEVICES_DATA: 'SET_IS_FETCHING_DEVICES_DATA',

  // Annotation
  SET_FRAME_STEP_SIZE: 'SET_FRAME_STEP_SIZE',
  SET_IS_SAVING_ANNOATION: 'SET_IS_SAVING_ANNOATION',
  SET_IS_SUBSTEPS_CHANGED: 'SET_IS_SUBSTEPS_CHANGED',

  // Task
  SET_TASKS: 'SET_TASK',
  SET_RECTIFICATION_STEPS: 'SET_RECTIFICATION_STEPS',
  SET_TRAINED_TASKS: 'SET_TRAINED_TASKS',
  SET_SELECTED_TASK: 'SET_SELECTED_TASK',
  SET_IS3D_TASK: 'SET_IS3D_TASK',
  SET_TASK_NAME: 'SET_TASK_NAME',
  SET_TASK_DETAILS: 'SET_TASK_DETAILS',
  SET_TASK_STEPS_COUNT: 'SET_TASK_STEPS_COUNT',
  SET_IS_TASK_STARRED: 'SET_IS_TASK_STARRED',
  SET_IS_MULTI_PROCESS_TASK: 'SET_IS_MULTI_PROCESS_TASK',
  SET_PROCESS_TO_STEP_LIST_MAP: 'SET_PROCESS_TO_STEP_LIST_MAP',
  SET_TASK_JSON: 'SET_TASK_JSON',
  SET_NEGATIVE_STEPS: 'SET_NEGATIVE_STEPS',
  SET_TASK_PROCESSES: 'SET_TASK_PROCESSES',
  SET_STEP_TO_NEGATIVE_MAPPING: 'SET_STEP_TO_NEGATIVE_MAPPING',
  SET_PROCESS: 'SET_PROCESS',
  SET_IS_ADDING_TRANSLATION: 'SET_IS_ADDING_TRANSLATION',
  SET_IS_DELETING_TRANSLATION: 'SET_IS_DELETING_TRANSLATION',
  SET_IS_TRANSLATION: 'SET_IS_TRANSLATION',
  SET_IS_TRANSLATION_EDIT: 'SET_IS_TRANSLATION_EDIT',
  SET_TRANSLATED_TASK_NAME: 'SET_TRANSLATED_TASK_NAME',
  SET_TRANSLATION_DETAILS: 'SET_TRANSLATION_DETAILS',
  SET_TRANSLATION_LANGUAGE: 'SET_TRANSLATION_LANGUAGE',
  SET_PROCESS_TRANSLATION: 'SET_PROCESS_TRANSLATION',
  SET_SELECTED_LANGUAGE: 'SET_SELECTED_LANGUAGE',
  SET_POC_FLAG: 'SET_POC_FLAG',
  SET_POLICY_FLAG: 'SET_POLICY_FLAG',
  SET_PREV_STEPS_JSON: 'SET_PREV_STEPS_JSON',
  SET_CURRENT_EDIT_MODE: 'SET_CURRENT_EDIT_MODE',
  SET_NEW_TASK: 'SET_NEW_TASK',
  SET_IS_TASK_EDIT: 'SET_IS_TASK_EDIT',
  SET_TASK_STRING: 'SET_TASK_STRING',
  SET_UPDATED_JSON_PAYLOAD: 'SET_UPDATED_JSON_PAYLOAD',
  SET_RES_JSON_FOR_ADD_DELETE_PHASE: 'SET_RES_JSON_FOR_ADD_DELETE_PHASE',
  SET_TASK_UPDATE_CONFIRM_MODAL: 'SET_TASK_UPDATE_CONFIRM_MODAL',
  SET_IS_FETCHING_TASKS: 'SET_IS_FETCHING_TASKS',
  SET_MISSED_STEP_SELECTED: 'SET_MISSED_STEP_SELECTED',
  SET_IS_FETCHING_TASK_DETAILS: 'SET_IS_FETCHING_TASK_DETAILS',
  SET_IS_FETCHING_TASK_STATUS: 'SET_IS_FETCHING_TASK_STATUS',
  SET_IS_ADDING_TASK: 'SET_IS_ADDING_TASK',
  SET_IS_UPDATING_TASK_FIELDS: 'SET_IS_UPDATING_TASK_FIELDS',
  SET_SIMILAR_STEPS: 'SET_SIMILAR_STEPS',
  SET_SHOW_SIMILAR_STEPS_MODAL: 'SET_SHOW_SIMILAR_STEPS_MODAL',
  SET_IS_UPDATING_SIMILAR_STEPS: 'SET_IS_UPDATING_SIMILAR_STEPS',
  SET_INDEX_TO_STEPS_MAPPING: 'SET_INDEX_TO_STEPS_MAPPING',
  SET_STEPS_TO_INDEX_MAPPING: 'SET_STEPS_TO_INDEX_MAPPING',
  SET_TASK_OBJECTS: 'SET_TASK_OBJECTS',
  SET_PLANE_OBJECTS: 'SET_PLANE_OBJECTS',
  SET_TASK_OBJECT_RULES: 'SET_TASK_OBJECT_RULES',
  SET_IS_SAVING_OBJECT_DETECTION_RULE: 'SET_IS_SAVING_OBJECT_DETECTION_RULE',
  SET_SHOW_STEP_TIME_MODAL: 'SET_SHOW_STEP_TIME_MODAL',
  SET_STEP_TIME: 'SET_STEP_TIME',
  SET_IS_UPDATING_STEP_TIME: 'SET_IS_UPDATING_STEP_TIME',
  SET_IS_STEP_TIME_EXIST: 'SET_IS_STEP_TIME_EXIST',
  SET_SHOW_TASK_PARAMS_MODAL: 'SET_SHOW_TASK_PARAMS_MODAL',
  SET_TASK_PARAMETERS: 'SET_TASK_PARAMETERS',
  SET_IS_UPDATING_TASK_PARAMS: 'SET_IS_UPDATING_TASK_PARAMS',
  SET_UPDATE_TASK_FROM_ADMIN: 'SET_UPDATE_TASK_FROM_ADMIN',
  SET_TASK_ORGANIZATION: 'SET_TASK_ORGANIZATION',
  SET_SHOW_REGION_DEFINE_MODAL: 'SET_SHOW_REGION_DEFINE_MODAL',
  SET_SHOW_ASSOCIATE_REGIONS_MODAL: 'SET_SHOW_ASSOCIATE_REGIONS_MODAL',
  SET_TASK_STATIC_OBJECTS: 'SET_TASK_STATIC_OBJECTS',
  SET_TASK_REGION_MARK_IMAGE_URL: 'SET_TASK_REGION_MARK_IMAGE_URL',
  SET_STEP_TO_ATTRIBUTES_MAPPING: 'SET_STEP_TO_ATTRIBUTES_MAPPING',
  SET_OPTIONAL_STEPS: 'SET_OPTIONAL_STEPS',
  SET_RES_JSON_FOR_RENAME_PHASE: 'SET_RES_JSON_FOR_RENAME_PHASE',
  SET_MERGE_STEPS_DICTIONARY: 'SET_MERGE_STEPS_DICTIONARY',
  SET_SUB_STEP_TO_STEP_MAPPING: 'SET_SUB_STEP_TO_STEP_MAPPING',
  SET_STEPS_JSON_DATA: 'SET_STEPS_JSON_DATA',
  SET_SUB_STEP_TIMES_JSON_DATA: 'SET_SUB_STEP_TIMES_JSON_DATA',
  SHOW_MODEL_VERSIONS_MODAL: 'SHOW_MODEL_VERSIONS_MODAL',
  SHOW_ANNOTATION_OBJECTS_MODAL: 'SHOW_ANNOTATION_OBJECTS_MODAL',
  CURR_OBJ_ANNOTATION_MODE: 'CURR_OBJ_ANNOTATION_MODE',
  SET_ANNOTATION_OBJ_JSON: 'SET_ANNOTATION_OBJ_JSON',
  SET_HAS_OBJECT_WARNING: 'SET_HAS_OBJECT_WARNING',
  SET_OBJECT_DETECTION_RULE_UPDATE_DATA:
    'SET_OBJECT_DETECTION_RULE_UPDATE_DATA',
  SET_ID_TO_OBJ_MAPPING: 'SET_ID_TO_OBJ_MAPPING',
  SET_OBJ_TO_ID_MAPPING: 'SET_OBJ_TO_ID_MAPPING',
  SET_STEPS_TRANSLATION_MAPPING: 'SET_STEPS_TRANSLATION_MAPPING',
  SET_STEPS_ASSOCIATION_IDS: 'SET_STEPS_ASSOCIATION_IDS',
  SET_SHOW_OD_SETUP_MODAL: 'SET_SHOW_OD_SETUP_MODAL',
  SET_SHOW_PROJECTOR_REGION_MODAL: 'SET_SHOW_PROJECTOR_REGION_MODAL',
  SET_TASK_STATIC_IMAGE_ID: 'SET_TASK_STATIC_IMAGE_ID',
  SET_SHOW_UPLOAD_MEDIA_MODAL: 'SET_SHOW_UPLOAD_MEDIA_MODAL',
  SET_TASK_STEP_MEDIA_OBJ: 'SET_TASK_STEP_MEDIA_OBJ',

  // Organization
  SET_ORGANIZATION_LIST: 'SET_ORGANIZATION_LIST',
  SET_ORG_PAGINATED_LIST: 'SET_ORG_PAGINATED_LIST',
  SET_ORG_TOTAL_COUNT: 'SET_ORG_TOTAL_COUNT',
  SET_IS_LOADING_ORGANIZATIONS: 'SET_IS_LOADING_ORGANIZATIONS',
  SET_ORG_PAGE: 'SET_ORG_PAGE',

  // WebRTC
  SET_WEBSOCKET_CONNECTION: 'SET_WEBSOCKET_CONNECTION',

  // Roles
  GET_ROLES: 'GET_ROLES',
  ADD_ROLE: 'ADD_ROLE',

  // Users
  GET_PENDING_USERS: 'GET_PENDING_USERS',

  // Devices
  SET_TASK_CROP_PARAMS: 'SET_TASK_CROP_PARAMS',
  RESET_DEVICE_STATE: 'RESET_DEVICE_STATE',
  SET_DEVICES: 'SET_DEVICES',
  SET_ACTIVE_DEVICES: 'SET_ACTIVE_DEVICES',
  SET_MONITORING_STATUS: 'SET_MONITORING_STATUS',
  SET_DEVICE_TASKS: 'SET_DEVICE_TASKS',
  SET_IS_FETCHING_DEVICES: 'SET_IS_FETCHING_DEVICES',
  SET_DEVICE_SERIAL_NUM_TO_DISPLAY_NAME_MAP:
    'SET_DEVICE_SERIAL_NUM_TO_DISPLAY_NAME_MAP',
  SET_DISPLAY_NAME_TO_DEVICE_SERIAL_NUM_MAP:
    'SET_DISPLAY_NAME_TO_DEVICE_SERIAL_NUM_MAP',
  SET_DEVICE_ID_TO_NAME_MAP: 'SET_DEVICE_ID_TO_NAME_MAP',
  SET_DEVICE_ID_TO_DEVICE_SERIAL_NUM_MAP:
    'SET_DEVICE_ID_TO_DEVICE_SERIAL_NUM_MAP',
  SET_DEVICE_FOR_DETAIL: 'SET_DEVICE_FOR_DETAIL',
  SET_ALL_DEVICES: 'SET_ALL_DEVICES',

  // Barcode
  SET_BARCODES: 'SET_BARCODES',
  SET_CREATING_BARCODES: 'SET_CREATING_BARCODES',
  SET_DELETING_BARCODES: 'SET_DELETING_BARCODES',
  SET_IS_FETCHING_BARCODES: 'SET_IS_FETCHING_BARCODES',
  SET_IS_FETCHING_ASSOCIATE_BARCODES: 'SET_IS_FETCHING_ASSOCIATE_BARCODES',
  SET_ASSOCIATE_BARCODES: 'SET_ASSOCIATE_BARCODES',
  SET_ADD_ASSOCIATE_BARCODES: 'SET_ADD_ASSOCIATE_BARCODES',
  SET_UPDATE_ASSOCIATE_BARCODES: 'SET_UPDATE_ASSOCIATE_BARCODES',
  SET_UPSERTING_ASSOCIATE_BARCODES: 'SET_UPSERTING_ASSOCIATE_BARCODES',
  SET_IS_REMOVING_ASSOCIATED_BARCODE: 'SET_IS_REMOVING_ASSOCIATED_BARCODE',

  // Telemetry
  SET_SELECTED_CYCLE_TYPE: 'SET_SELECTED_CYCLE_TYPE',
  SET_IS_FETCHING_CYCLES: 'SET_IS_FETCHING_CYCLES',
  SET_IS_FETCHING_TRACE: 'SET_IS_FETCHING_TRACE',
  SHOW_COMPARE_ANALYTICS: 'SHOW_COMPARE_ANALYTICS',
  SET_DATE_RANGE_FOR_COMPARISON: 'SET_DATE_RANGE_FOR_COMPARISON',
  SET_IS_FETCHING_ANALYTICS_FOR_COMPARISON:
    'SET_IS_FETCHING_ANALYTICS_FOR_COMPARISON',
  SET_IS_FETCHING_META_DATA: 'SET_IS_FETCHING_META_DATA',
  SET_META_DATA: 'SET_META_DATA',

  //Obeya Room
  SET_VIDEOS_LIST: 'SET_VIDEOS_LIST',
  SET_VIDEOS_COUNT: 'SET_VIDEOS_COUNT',
  SET_IS_FETCHING_VIDEOS: 'SET_IS_FETCHING_VIDEOS',
  SET_SELECTED_VIDEO_TYPE: 'SET_SELECTED_VIDEO_TYPE',
  SET_ORGANIZATION_TAGS: 'SET_ORGANIZATION_TAGS',

  // AWS-Lambda Token
  SET_USER_POOL_CONFIG: 'SET_USER_POOL_CONFIG',
  SET_IS_FETCHING_CONFIG: 'SET_IS_FETCHING_CONFIG',
  SET_CREATING_AWS_TOKEN: 'SET_CREATING_AWS_TOKEN',

  // Feature-Control
  GET_FEATURES: 'GET_FEATURES',
  ADD_FEATURE: 'ADD_FEATURE',
  DELETE_FEATURE: 'DELETE_FEATURE',
  SET_FEATURE_SUBSCRIPTIONS: 'SET_FEATURE_SUBSCRIPTIONS',
  ADD_FEATURE_SUBSCRIPTION: 'ADD_FEATURE_SUBSCRIPTION',
  DELETE_FEATURE_SUBSCRIPTION: 'DELETE_FEATURE_SUBSCRIPTION',

  //Binary Deployments
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_RELEASES: 'SET_RELEASES',
  SET_LATEST_BRANCH_ID: 'SET_LATEST_BRANCH_ID',
  SET_LATEST_DATE: 'SET_LATEST_DATE',
  SET_LATEST_DEPLOYMENT: 'SET_LATEST_DEPLOYMENT',
  SET_CURRENT_PAGE_RELEASES: 'SET_CURRENT_PAGE_RELEASES',
  SET_RELEASES_COUNT: 'SET_RELEASES_COUNT',
  SET_BRANCH_TO_MOVE: 'SET_BRANCH_TO_MOVE',
  SET_RECORD_TO_DELETE: 'SET_RECORD_TO_DELETE',
  SET_LATEST_RETRO_GUI_BRANCH_DEV: 'SET_LATEST_RETRO_GUI_BRANCH_DEV',

  // MODEL VERSIONS
  SET_MODEL_VERSIONS_LIST: 'SET_MODEL_VERSIONS_LIST',
  SET_IS_FETCHING_MODEL_VERSIONS: 'SET_IS_FETCHING_MODEL_VERSIONS',
  SET_IS_UPDATING_MODEL_VERSION: 'SET_IS_UPDATING_MODEL_VERSION',
  SET_IS_DELETING_MODEL_VERSION: 'SET_IS_DELETING_MODEL_VERSION',
  SET_TASK_ASSOCIATE_MODEL_VERSION: 'SET_TASK_ASSOCIATE_MODEL',
  SET_IS_UPDATING_ASSOCIATE_MODEL_VERSION: 'SET_IS_UPDATING_ASSOCIATE_MODEL',
  SET_SHOW_UPLOAD_MODEL_BUTTON: 'SET_SHOW_UPLOAD_MODEL_BUTTON',

  // FCM
  SET_FCM_TOKEN: 'SET_FCM_TOKEN',
  // Remote Camera
  SET_REMOTE_CAMS_LIST: 'SET_REMOTE_CAMS_LIST',
  SET_IS_FETCHING_CAMS_LIST: 'SET_IS_FETCHING_CAMS_LIST',
  SET_SHOW_REMOTE_CAM_MODAL: 'SET_SHOW_REMOTE_CAM_MODAL',
  SET_REMOTE_CAM_MODAL_LOADER: 'SET_REMOTE_CAM_MODAL_LOADER',
  //Projector
  SET_SERIAL_NUMBER: 'SET_SERIAL_NUMBER',
  SET_RTC_CONNECTION: 'SET_RTC_CONNECTION',
  SET_DATA_CHANNEL: 'SET_DATA_CHANNEL',
  SET_DATA_CHANNEL_PC: 'SET_DATA_CHANNEL_PC',
  SET_ANSWER_SEARCHING: 'SET_ANSWER_SEARCHING',
  SET_ANSWER_SEARCHING_DC: 'SET_ANSWER_SEARCHING_DC',
  SET_DATA_CHANNEL_ICE: 'SET_DATA_CHANNEL_ICE',
  SET_SESSION_ID: 'SET_SESSION_ID',
  SET_SESSION_ID_DC: 'SET_SESSION_ID_DC',
  SET_RTC_CONFIGURATION: 'SET_RTC_CONFIGURATION',
  SET_IS_EDIT_REMOTE_DEVICE: 'SET_IS_EDIT_REMOTE_DEVICE',

  // Virtual Tasks
  SET_VIRTUAL_TASKS: 'SET_VIRTUAL_TASKS',
  SET_SELECTED_VIRTUAL_TASK: 'SET_SELECTED_VIRTUAL_TASK',
  SET_VIRTUAL_TASK_NEGATIVE_STEPS: 'SET_VIRTUAL_TASK_NEGATIVE_STEPS',
  SET_VIRTUAL_TASK_OPTIONAL_STEPS: 'SET_VIRTUAL_TASK_OPTIONAL_STEPS',
  SET_VIRTUAL_TASK_INDEX_TO_STEP_MAP: 'SET_VIRTUAL_TASK_INDEX_TO_STEP_MAP',
  SET_TASK_CREATED_AT_DATE: 'SET_TASK_CREATED_AT_DATE',
  SET_IS_PARENT_OF_VIRTUAL_TASKS: 'SET_IS_PARENT_OF_VIRTUAL_TASKS',

  // Model Types
  SET_MODEL_TYPES: 'SET_MODEL_TYPES',
  SET_IS_FETCHING_MODEL_TYPES: 'SET_IS_FETCHING_MODEL_TYPES',
  SET_SHOW_ADD_MODEL_TYPE_MODAL: 'SET_SHOW_ADD_MODEL_TYPE_MODAL',
  SET_IS_ADDING_MODEL_TYPE: 'SET_IS_ADDING_MODEL_TYPE',
  SET_MODEL_TYPE_TO_BE_EDITED: 'SET_MODEL_TYPE_TO_BE_EDITED',

  // Ergonomics
  SET_ERGONOMICS_VIDEOS_LIST: 'SET_ERGONOMICS_VIDEOS_LIST',
  SET_ERGONOMICS_VIDEOS_COUNT: 'SET_ERGONOMICS_VIDEOS_COUNT',
  SET_ERGONOMICS_LOADING: 'SET_ERGONOMICS_LOADING',
  SET_SELECTED_ERGONOMICS_RECORD: 'SET_SELECTED_ERGONOMICS_RECORD',
  SET_CURRENT_ERGONOMICS_RECORD_PAGE: 'SET_CURRENT_ERGONOMICS_RECORD_PAGE',
  SET_IS_FETCHING_JSON: 'SET_IS_FETCHING_JSON',
  SET_REBA_FRAMES_DATA: 'SET_REBA_FRAMES_DATA',
  SET_REBA_ANGLES_DATA: 'SET_REBA_ANGLES_DATA',
  SET_RULA_FRAMES_DATA: 'SET_RULA_FRAMES_DATA',
  SET_OWAS_FRAMES_DATA: 'SET_OWAS_FRAMES_DATA',
  SET_LOAD_PERCENTAGE_DATA: 'SET_LOAD_PERCENTAGE_DATA',
  SET_LOAD_TIMESTAMPS_DATA: 'SET_LOAD_TIMESTAMPS_DATA',

  // Kaizen Copilot
  SET_MESSAGES: 'SET_MESSAGES',
  SET_CURRENT_KAIZEN_OPTION: 'SET_CURRENT_KAIZEN_OPTION',
  SET_SELECTED_SHEET: 'SET_SELECTED_SHEET',
  SET_KAIZEN_EXTRAS: 'SET_KAIZEN_EXTRAS',

  // Object Detection
  SET_IMAGES_FOR_ANNOTATION: 'SET_IMAGES_FOR_ANNOTATION',
  SET_ANNOTATION_IMAGES_LOADING: 'SET_ANNOTATION_IMAGES_LOADING',
  SET_TOTAL_ANNOTATION_IMAGES: 'SET_TOTAL_ANNOTATION_IMAGES',
  SET_PREV_PAGE_LAST_IMAGE_OBJ: 'SET_PREV_PAGE_LAST_IMAGE_OBJ',
  SET_PREV_GROUPS: 'SET_PREV_GROUPS',
  SET_PREV_POLYGONS: 'SET_PREV_POLYGONS',
  SET_MODEL_CHOICE: 'SET_MODEL_CHOICE',
  SET_MAX_CYCLE_TIME: 'SET_MAX_CYCLE_TIME',
  SET_TASK_CREATED_AT: 'SET_TASK_CREATED_AT',

  // Archived Cycle
  SET_SHOW_ADD_MODEL_ARCHIVED_CYCLE: 'SET_SHOW_ADD_MODEL_ARCHIVED_CYCLE',
  SET_ARCHIVED_CYCLE_LIST: 'SET_ARCHIVED_CYCLE_LIST',
  SET_ARCHIVED_FILTERED_CYCLE_LIST: 'SET_ARCHIVED_FILTERED_CYCLE_LIST',
  FILTER_ARCHIVED_CYCLE_MAPPED_LIST: 'FILTER_ARCHIVED_CYCLE_MAPPED_LIST',
  FILTER_ARCHIVED_CYCLE_LIST: 'FILTER_ARCHIVED_CYCLE_LIST',
  SET_KEYWORD: 'SET_KEYWORD',
  FILTER_ARCHIVED_CYCLE_LIST: 'FILTER_ARCHIVED_CYCLE_LIST',
};
